import TaskHelper from '@/services/task-helper';
import { ForgeExtension } from '@/components/Modules/Forge/extensions';

export class RfisExtension extends Autodesk.Viewing.Extension {
  constructor(viewer, options) {
    super(viewer, options);
    this._group = null;
    this._button = null;
    this._enabled = false;
    this._data = options.data || [];

    this.updateMarkupsStateCallback =
      this.updateMarkupsStateCallback.bind(this);
  }

  restoreState(state) {
    const extState = state['RfisExtension'] || {};
    this._data = extState.data || [];
    this.showIcons(this._enabled);
  }

  updateMarkupsStateCallback() {
    this._enabled = false;
    this.showIcons(this._enabled);
    this._button.setState(this._enabled ? 0 : 1);
  }

  load() {
    const updateIconsCallback = () => {
      if (this._enabled) {
        this.updateIcons();
      }
    };

    const buttons = document.querySelectorAll('.adsk-button');
    buttons.forEach((b) => {
      if (!b.classList.contains('rfi-toggle')) {
        b.addEventListener('click', this.updateMarkupsStateCallback);
      }
    });

    this.viewer.addEventListener(
      Autodesk.Viewing.CAMERA_CHANGE_EVENT,
      updateIconsCallback
    );
    this.viewer.addEventListener(
      Autodesk.Viewing.ISOLATE_EVENT,
      updateIconsCallback
    );
    this.viewer.addEventListener(
      Autodesk.Viewing.HIDE_EVENT,
      updateIconsCallback
    );
    this.viewer.addEventListener(
      Autodesk.Viewing.SHOW_EVENT,
      updateIconsCallback
    );
    this.viewer.addEventListener(
      Autodesk.Viewing.TOOL_CHANGE_EVENT,
      updateIconsCallback
    );
    this.viewer.addEventListener(
      Autodesk.Viewing.EXPLODE_CHANGE_EVENT,
      this.updateMarkupsStateCallback
    );
    return true;
  }

  unload() {
    // Clean our UI elements if we added any
    if (this._group) {
      this._group.removeControl(this._button);
      if (this._group.getNumberOfControls() === 0) {
        this.viewer.toolbar.removeControl(this._group);
      }
    }
    const buttons = document.querySelectorAll('.adsk-button');
    buttons.forEach((b) => {
      if (!b.classList.contains('rfi-toggle')) {
        b.removeEventListener('click', this.updateMarkupsStateCallback);
      }
    });

    return true;
  }

  onToolbarCreated() {
    // Create a new toolbar group if it doesn't exist
    this._group = this.viewer.toolbar.getControl('customExtensions');
    if (!this._group) {
      this._group = new Autodesk.Viewing.UI.ControlGroup('customExtensions');
      this.viewer.toolbar.addControl(this._group);
    }

    // Add a new button to the toolbar group
    this._button = new Autodesk.Viewing.UI.Button('IconExtension');
    this._button.onClick = (e) => {
      this._enabled = !this._enabled;
      this.showIcons(this._enabled);
      this._button.setState(this._enabled ? 0 : 1);
    };
    this._button.container.classList.add('rfi-toggle');
    this._button.setToolTip(this.options.button.tooltip);
    this._button.container.children[0].innerHTML = this.options.button.icon;
    this._group.addControl(this._button);
  }

  showIcons(show) {
    const viewerDiv = document.getElementById(this.viewer.clientContainer.id);
    const viewerContainer = viewerDiv.querySelector('div.adsk-viewing-viewer');

    // remove previous...
    const markups = viewerContainer.querySelectorAll('div.markup');
    markups.forEach((markup) => markup.remove());
    if (!show) return;

    // do we have anything to show?
    if (this._data === undefined || this.data === null) return;

    const onClick = (e) => {
      const rfiId = e.currentTarget.dataset.rfi;
      this.options.onClick(rfiId);
    };

    this._data.forEach((item) => {
      // create the markup
      const markupDiv = document.createElement('div');
      markupDiv.classList.add('markup', 'update');
      markupDiv.dataset.sbs = item.sbsCode;
      markupDiv.dataset.rfi = item.rfiId;
      markupDiv.innerHTML = TaskHelper.getMarkerSvgString(
        item.color,
        item.number,
        item.taskAssigned
      );
      markupDiv.addEventListener('click', onClick);
      viewerContainer.appendChild(markupDiv);
      this.updateIcons();
    });
  }

  updateIcons() {
    const markups = Array.from(
      document.querySelectorAll(
        `#${this.viewer.clientContainer.id} div.adsk-viewing-viewer .update`
      )
    );

    markups.forEach((markup) => {
      const rfiId = markup.dataset.rfi;

      const point = this._data.find((t) => t.rfiId === rfiId).point;

      if (point) {
        const convertedPoint = new THREE.Vector3(point.x, point.y, point.z);
        const pos = this.viewer.worldToClient(convertedPoint);
        markup.style.position = 'absolute';
        markup.style.height = '74px';
        markup.style.width = '27px';
        markup.style.cursor = 'pointer';

        markup.style.left = `${Math.floor(pos.x - markup.offsetWidth / 2)}px`;
        markup.style.top = `${Math.floor(pos.y - markup.offsetHeight / 2)}px`;
      }
      markup.style.display = this.viewer.isNodeVisible(rfiId)
        ? 'block'
        : 'none';
    });
  }
}

Autodesk.Viewing.theExtensionManager.registerExtension(
  ForgeExtension.ANT_RFIS_EXTENSION,
  RfisExtension
);
