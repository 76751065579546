<template>
  <div class="pos-rel flex d-flex flex-column overflow-y-auto">
    <slot name="forge-custom-actions" />

    <portal v-if="!antViewerToolbar" :to="`${toolbarPrefix}ant-toolbar`">
      <div></div>
    </portal>
    <portal :order="1" :to="`${toolbarPrefix}ant-toolbar-left`">
      <v-tooltip
        v-if="antToolbarOptions.sidebar && antToolbarOptions.sidebar.display"
        key="forge-viewer.sidebar"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            :color="showRightPanel ? 'primary' : ''"
            :ripple="false"
            icon
            tile
            v-bind="attrs"
            @click="toggleRightPanel"
            v-on="on"
          >
            <v-icon> mdi-page-layout-sidebar-right</v-icon>
          </v-btn>
        </template>
        <span>Toggle viewer sidebar</span>
      </v-tooltip>
      <v-divider
        v-if="antToolbarOptions.sidebar && antToolbarOptions.sidebar.display"
        class="mx-2"
        inset
        vertical
      />
      <v-tooltip
        v-if="
          antToolbarOptions.performanceMode &&
          antToolbarOptions.performanceMode.display
        "
        key="forge-viewer.performanceMode"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            :color="performanceModeToggle ? 'primary' : ''"
            :ripple="false"
            icon
            tile
            v-bind="attrs"
            @click="performanceModeToggle = !performanceModeToggle"
            v-on="on"
          >
            <v-icon> mdi-power-cycle</v-icon>
          </v-btn>
        </template>
        <span>performance mode</span>
      </v-tooltip>
      <v-tooltip
        v-if="antToolbarOptions.models && antToolbarOptions.models.display"
        key="forge-viewer.models"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            :color="modelsToggle ? 'primary' : ''"
            :ripple="false"
            icon
            tile
            v-bind="attrs"
            @click="modelsToggle = !modelsToggle"
            v-on="on"
          >
            <v-icon> mdi-table-column</v-icon>
          </v-btn>
        </template>
        <span>View models</span>
      </v-tooltip>
      <v-tooltip
        v-if="antToolbarOptions.antTable && antToolbarOptions.antTable.display"
        key="forge-viewer.antTable"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            :color="tableToggle ? 'primary' : ''"
            :ripple="false"
            icon
            tile
            v-bind="attrs"
            @click="toggleAntTable"
            v-on="on"
          >
            <v-icon> mdi-table-network</v-icon>
          </v-btn>
        </template>
        <span>Display ANT table</span>
      </v-tooltip>
      <v-tooltip
        v-if="
          antToolbarOptions.modelTree && antToolbarOptions.modelTree.display
        "
        key="forge-viewer.modelTree"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            :color="modelTreeToggle ? 'primary' : ''"
            :ripple="false"
            icon
            tile
            v-bind="attrs"
            @click="toggleModelTree"
            v-on="on"
          >
            <v-icon> mdi-file-tree</v-icon>
          </v-btn>
        </template>
        <span>Display Model Tree</span>
      </v-tooltip>
      <v-tooltip
        v-if="
          antToolbarOptions.objectProperties &&
          antToolbarOptions.objectProperties.display
        "
        key="forge-viewer.objectProperties"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            :color="objectPropertiesToggle ? 'primary' : ''"
            :ripple="false"
            icon
            tile
            v-bind="attrs"
            @click="toggleObjectProperties"
            v-on="on"
          >
            <v-icon> mdi-chart-tree</v-icon>
          </v-btn>
        </template>
        <span>Display Object properties</span>
      </v-tooltip>
      <v-tooltip
        v-if="antToolbarOptions.viewType && antToolbarOptions.viewType.display"
        key="forge-viewer.viewType"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            :ripple="false"
            icon
            tile
            v-bind="attrs"
            @click="selectViewType()"
            v-on="on"
          >
            <v-icon v-if="selectedViewType === 1">
              mdi-perspective-more
            </v-icon>
            <v-icon v-if="selectedViewType === 2">
              mdi-perspective-less
            </v-icon>
          </v-btn>
        </template>
        <span v-if="selectedViewType === 1">Select perspective view</span>
        <span v-if="selectedViewType === 2">Select orthographic view</span>
      </v-tooltip>
      <v-tooltip
        v-if="
          antToolbarOptions.clearIsolation &&
          antToolbarOptions.clearIsolation.display
        "
        key="forge-viewer.clearIsolation"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn icon tile v-bind="attrs" @click="clearSearch" v-on="on">
            <v-icon> mdi-filter-off</v-icon>
          </v-btn>
        </template>
        <span>Clear isolation</span>
      </v-tooltip>
      <v-tooltip
        v-if="antToolbarOptions.ghosting && antToolbarOptions.ghosting.display"
        key="forge-viewer.ghosting"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn icon tile v-bind="attrs" @click="toggleGhosting" v-on="on">
            <v-icon>
              {{ ghostToggle ? 'mdi-ghost-off' : 'mdi-ghost' }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('modules.forge.toggleGhosting') }}</span>
      </v-tooltip>
      <v-divider class="mx-2" inset vertical />
      <slot name="ant-forge-toolbar-actions" />
    </portal>
    <portal :to="`${toolbarPrefix}ant-toolbar-right`">
      <v-text-field
        v-if="displaySearch"
        v-model="searchPhrase"
        append-inner-icon="mdi-close"
        clearable
        dense
        filled
        flat
        hide-details
        placeholder="Search in model"
        prepend-inner-icon="mdi-magnify"
        style="height: 35px"
        type="text"
        @change="searchInModel"
        @click:clear="clearSearch"
      />
    </portal>

    <div class="d-flex flex-grow-1 overflow-hidden pos-rel">
      <div class="d-flex flex-grow-1">
        <div id="ant_viewer_forge" class="flex-grow-1">
          <div class="ant-forge-overlay-container">
            <transition name="fade-in">
              <div
                v-if="modelsToggle"
                class="ant-glass-background forge-models"
              >
                <v-text-field
                  v-model="modelsSearch"
                  append-inner-icon="mdi-close"
                  class="forge-models-search"
                  clearable
                  dense
                  filled
                  flat
                  hide-details
                  placeholder="Search in models"
                  prepend-inner-icon="mdi-magnify"
                  rounded
                  single-line
                  type="text"
                />
                <v-list
                  class="forge-models-list"
                  dense
                  style="background-color: transparent"
                >
                  <v-list-item v-for="model in filteredModels" :key="model.id">
                    <v-list-item-action>
                      <v-checkbox
                        v-model="model.enabled"
                        @change="loadViewer"
                      />
                    </v-list-item-action>
                    <div class="d-flex flex-column">
                      <v-list-item-title>{{ model.name }}</v-list-item-title>
                      <v-list-item-subtitle class="fs-8">
                        version:
                        {{ model.version ?? '-' }}
                      </v-list-item-subtitle>
                    </div>
                  </v-list-item>
                </v-list>
              </div>
            </transition>

            <slot
              class="full-height full-width"
              name="forge-overlay-container"
            />
          </div>
        </div>

        <div v-if="isSidebarEnabled" class="ant-forge-sidebar-right">
          <panel-resizable
            v-if="
              showRightPanel &&
              antToolbarOptions.sidebar &&
              showRightPanel &&
              antToolbarOptions.sidebar.display
            "
            :default-width="400"
            :min-width="300"
            class="ant-glass-background ant-border-left radius-0 full-height"
            side="right"
            @collapse="showRightPanel = false"
            @resize-end="resizeViewEvent"
          >
            <div class="full-height pa-2">
              <slot name="sidebar-top" />
              <div
                v-if="modelTreeToggle"
                class="my-5 py-5 overflow-x-auto ant-border-top ant-border-bottom"
              >
                <v-select
                  v-model="selectedModelTree"
                  :items="models.filter((model) => model.enabled)"
                  item-text="name"
                  label="Model"
                  return-object
                />
                <v-treeview
                  :items="modelTree"
                  dense
                  item-children="objects"
                  item-key="objectid"
                >
                  <template slot="label" slot-scope="{ item }">
                    <div
                      class="c-pointer"
                      style="font-size: 12px"
                      @click="isolateModelTreeObject(item)"
                    >
                      {{ item.name }}
                    </div>
                  </template>
                </v-treeview>
              </div>
              <div
                v-if="objectPropertiesToggle"
                class="my-5 py-5 ant-border-top ant-border-bottom"
              >
                <v-treeview
                  v-if="objectPropertiesTree.length > 0"
                  :items="objectPropertiesTree"
                  class="overflow-y-auto pr-2"
                  dense
                >
                  <template #label="{ item }">
                    <div class="d-flex" style="font-size: 12px">
                      <div class="flex-grow-1">
                        {{ item.name }}
                      </div>
                      <div class="flex-grow-1 text-right">
                        {{ item.value }}
                      </div>
                    </div>
                  </template>
                </v-treeview>
                <div
                  v-else
                  class="text-center font-italic"
                  style="font-size: 12px"
                >
                  Select object in 3D viewer
                </div>
              </div>
              <slot name="sidebar-bottom" />
            </div>
          </panel-resizable>
        </div>
      </div>

      <div class="ant-forge-bottom-panel">
        <transition mode="out-in" name="slide-fade-down">
          <panel-resizable
            v-show="tableToggle"
            :default-height="400"
            :min-height="300"
            class="ant-glass-background ant-border-top"
            @collapse="tableToggle = false"
          >
            <ant-tables v-show="tableToggle" :is-on-view="tableToggle" />
          </panel-resizable>
        </transition>
      </div>
    </div>
    <v-tooltip
      :value="showRfiNotificationTooltip"
      absolute
      attach="#ant_viewer_forge"
      right
      top
    >
      <span
        >To create an rfi task you need <br />
        to click on some fragment</span
      >
    </v-tooltip>
    <rfi-dialog
      key="forge-rfi-dialog"
      :is-shown="showRfiDialog"
      :options="rfiOptions"
      :project="project"
      :sbs-object="modelSbs"
      @changeRfi="onRfiCreated"
      @closeDialog="rfiDialogClose"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Portal } from 'portal-vue';
import AutodeskService from '@/services/forge/autodesk';
import { ViewerService } from '@/services/forge/viewer';
import { ACC_ROUTE } from '@/services/forge/autodesk-construction-cloud';
import { RfisExtension } from '@/services/forge/extensions/ant-rfis-extension';
import ColorHelper from '@/services/color-helper';
import { getSbsRecords } from '@/services/api/sbs.api';
import { cloneDeep } from 'lodash';
import { ForgeExtension } from '@/components/Modules/Forge/extensions';
import { getObjectsWithMappingLevel } from '@/components/Modules/Daiwa-House-Modular-Europe/utils/DHME+utils';
import appConfig from '@/appConfig';

export default {
  name: 'ForgeViewer',
  components: {
    Portal,
    AntTables: () => import('@/components/AntTables'),
    PanelResizable: () => import('@/components/Project/PanelResizable'),
    RfiDialog: () => import('@/components/Tasks/RFI/RfiDialog.vue'),
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
    models: {
      type: Array,
      required: true,
    },
    /**
     * Check @/components/Modules/Forge/extensions.js to see all available extensions
     * */
    extensions: {
      type: Array,
      required: true,
    },
    customExtensions: {
      type: Array,
      default: () => [],
    },
    extensionOptions: {
      type: Array,
      required: true,
    },
    headless: {
      type: Boolean,
      default: false,
    },
    displaySearch: {
      type: Boolean,
      default: true,
    },
    antViewerToolbar: {
      type: Boolean,
      default: true,
    },
    antToolbarPrefix: {
      type: String,
      default: '',
    },
    antToolbarOptions: {
      type: Object,
      default: () => {
        return {
          viewType: {
            display: true,
            enabled: false,
          },
          performanceMode: {
            display: true,
            enabled: true,
          },
          models: {
            display: true,
            enabled: false,
          },
          antTable: {
            display: true,
            enabled: false,
          },
          ghosting: {
            display: true,
            enabled: false,
          },
          modelTree: {
            display: true,
            enabled: false,
          },
          clearIsolation: {
            display: true,
            enabled: false,
          },
          objectProperties: {
            display: true,
            enabled: false,
          },
          sidebar: {
            display: false,
            enabled: true,
            width: 400,
          },
        };
      },
    },
  },
  data: () => {
    return {
      viewerService: undefined,
      searchPhrase: undefined,
      modelsToggle: false,
      performanceModeToggle: true,
      tableToggle: false,
      ghostToggle: false,
      modelTreeToggle: false,
      selectedModelTree: undefined,
      objectPropertiesToggle: false,
      showRightPanel: false,
      objectSelectionBind: undefined,
      objectPropertiesTree: [],
      modelsSearch: null,
      selectedViewType: 2,
      modelObjects: [],
      sidebarSize: 400,
      modelTree: [],
      externalIdMapping: null,
      externalIdMappings: {},
      showRfiDialog: false,
      showRfiNotificationTooltip: false,
      sbsRecords: [],
      modelSbs: null,
      modelsSbsByDbid: {},
      modelsSbsCodes: [],
      rfiOptions: {
        module: 'forge',
        rfiForgeCoordinates: {},
      },
    };
  },
  computed: {
    ...mapGetters([
      'selectedSbsObject',
      'sbsSidebarToggle',
      'sbsRenderEvent',
      'accAccessToken',
      'project',
      'tasksRfi',
      'task',
      'authenticatedUser',
    ]),

    isSidebarEnabled() {
      return this.showRightPanel;
    },

    filteredModels() {
      if (this.modelsSearch !== null) {
        return this.models.filter((model) =>
          model.name.toLowerCase().includes(this.modelsSearch.toLowerCase())
        );
      } else {
        return this.models;
      }
    },
    toolbarPrefix() {
      if (!this.antToolbarPrefix) return '';
      return `${this.antToolbarPrefix}-`;
    },
  },
  watch: {
    selectedSbsObject(newObject) {
      if (newObject) {
        this.highlightSbsCode();
      } else {
        this.searchPhrase = null;
        this.clearSearch();
      }
    },
    performanceModeToggle(value) {
      if (value) {
        this.viewerService.Viewer3D.setGroundShadow(false);
        this.viewerService.Viewer3D.setGhosting(false);
        this.viewerService.Viewer3D.setQualityLevel(false, false);
      } else {
        this.viewerService.Viewer3D.setGroundShadow(true);
        this.viewerService.Viewer3D.setGhosting(true);
        this.viewerService.Viewer3D.setQualityLevel(true, true);
      }
    },
    sbsSidebarToggle(value) {
      if (this.viewerService) {
        this.viewerService.resizeView();
      }
    },
    sbsRenderEvent() {
      if (this.viewerService) {
        this.viewerService.resizeView();
      }
    },
    selectedViewType(value) {
      this.viewerService.Viewer3D.setViewType(value);
    },
    selectedModelTree(value) {
      if (value) {
        this.fetchModelTree(value);
      }
    },
    antToolbarOptions: {
      deep: true,
      immediate: true,
      handler(value) {
        this.modelsToggle = value.models.enabled;
        this.tableToggle = value.antTable.enabled;
        this.ghostToggle = value.ghosting.enabled;
        this.modelTreeToggle = value.modelTree.enabled;
        this.showRightPanel = value.sidebar.enabled;

        if (value.sidebar.width) {
          this.sidebarSize = value.sidebar.width;
        }
      },
    },
    sidebarToggle(value) {
      if (value) {
        this.sidebarSize = 400;
      }
      this.resizeViewEvent();
    },
    task: {
      handler(task) {
        if (task) {
          this.showRfiDialog = true;
        }
      },
      deep: true,
    },
    models(models) {
      if (models.filter((model) => model.enabled).length) {
        this.loadViewer();
      }
    },
  },
  mounted() {
    if (this.project) {
      getSbsRecords(this.project?.id, {}).then(
        (res) => (this.sbsRecords = res)
      );
    }
    this.loadViewer();
  },
  methods: {
    highlightSbsCode() {
      this.modelObjects.forEach((item) => {
        let model = this.viewerService.Viewer3D.impl
          .modelQueue()
          .getModels()
          .concat()
          .find((model) => item.urn.includes(model.myData.urn));

        let modelRecord = this.models.find((m) => m.urn === item.urn);
        let sbsLevel = modelRecord.sbs_parameter.split('.');
        let objects = getObjectsWithMappingLevel(item.properties, sbsLevel);

        let sbsModelObjects = objects.filter(
          (object) =>
            sbsLevel.reduce((o, i) => o[i], object.properties) ===
            this.selectedSbsObject?.code
        );
        if (sbsModelObjects?.length > 0) {
          let ids = this.mapExternalIdsToObjectIdsMultiModel(
            model.myData.urn,
            sbsModelObjects.map((o) => o.externalId)
          );
          this.viewerService.Viewer3D.show(ids, model);
          this.viewerService.Viewer3D.isolate(ids, model);
          this.viewerService.Viewer3D.fitToView(ids, model);
        } else {
          let ids = this.mapExternalIdsToObjectIdsMultiModel(
            model.myData.urn,
            objects.map((o) => o.externalId)
          );
          this.viewerService.Viewer3D.hide(ids, model);
        }
      });
    },
    resizeViewEvent() {
      this.$nextTick(() => {
        if (this.viewerService) {
          this.viewerService.resizeView();
        }
      });
    },
    startResizeSidebar(e) {
      document.addEventListener('mousemove', this.dragSidebar);
      document.addEventListener('mouseup', this.stopResizeSidebar);
    },
    dragSidebar(e) {
      this.sidebarSize = window.innerWidth - e.clientX + 12;
    },
    stopResizeSidebar(e) {
      document.removeEventListener('mousemove', this.dragSidebar);
      document.removeEventListener('mouseup', this.stopResizeSidebar);

      if (this.sidebarSize < 100) {
        this.showRightPanel = false;
      }
      this.resizeViewEvent();
    },

    selectViewType() {
      switch (this.selectedViewType) {
        case 2:
          this.selectedViewType = 1;
          break;
        case 1:
          this.selectedViewType = 2;
          break;
      }
    },
    toggleAntTable() {
      this.tableToggle = !this.tableToggle;
      this.showRightPanel = false;
      this.resizeViewEvent();
    },
    toggleRightPanel() {
      this.showRightPanel = !this.showRightPanel;
      this.tableToggle = false;
      this.resizeViewEvent();
    },
    toggleGhosting() {
      this.ghostToggle = !this.ghostToggle;
      this.viewerService.Viewer3D.setGhosting(this.ghostToggle);
    },
    toggleObjectProperties() {
      this.objectPropertiesToggle = !this.objectPropertiesToggle;
      if (this.objectPropertiesToggle) {
        this.objectSelectionBind = this.objectSelectionEvent.bind(this);
        this.viewerService.Viewer3D.addEventListener(
          Autodesk.Viewing.SELECTION_CHANGED_EVENT,
          this.objectSelectionBind
        );
      } else {
        this.viewerService.Viewer3D.removeEventListener(
          Autodesk.Viewing.SELECTION_CHANGED_EVENT,
          this.objectSelectionBind
        );
        this.objectSelectionBind = undefined;
        this.objectPropertiesTree = [];
      }
    },
    objectSelectionEvent() {
      const selection = this.viewerService.Viewer3D.getSelection();
      if (selection.length === 1) {
        this.objectPropertiesTree = [];
        let externalIds = this.getExternalIdsByNewObjectIds(selection);

        let objects = this.modelObjects.flatMap((item) => item.properties);

        let objectsSelection = objects.filter((object) => {
          return externalIds.some((id) => id === object.externalId);
        });

        let object = objectsSelection[0];

        // reverse sbs lookup in tree
        let urn = this.modelObjects.find((item) =>
          item.properties.find((prop) => prop.externalId === object.externalId)
        ).urn;

        let sbsParameter = this.models.find(
          (record) => record.urn === urn
        ).sbs_parameter;

        if (sbsParameter && this.sbsSidebarToggle) {
          let sbscode = sbsParameter
            .split('.')
            .reduce((o, i) => o[i], object.properties);

          if (sbscode) {
            this.$store.dispatch('searchSbsTree', { searchValue: sbscode });
          }
        }

        Object.keys(object.properties).forEach((key) => {
          let treeItem = {
            name: key,
            children: [],
          };
          Object.keys(object.properties[key]).forEach((propKey) => {
            let childProp = {
              name: propKey,
              value: object.properties[key][propKey],
            };
            treeItem.children.push(childProp);
          });
          this.objectPropertiesTree.push(treeItem);
        });
      } else if (selection.length > 1) {
        this.$store.commit('showNotification', {
          content: `Can only select one object at once'`,
          color: 'error',
        });
      }
    },
    toggleModelTree() {
      this.modelTreeToggle = !this.modelTreeToggle;
      if (this.models.filter((model) => model.enabled).length === 1) {
        this.selectedModelTree = this.models[0];
        this.fetchModelTree(this.models[0]);
      }
    },
    fetchModelTree(model) {
      AutodeskService.getModelTree(
        model.urn,
        this.models
          .filter((model) => model.enabled)
          .filter((model) => model.from_acc).length > 0
          ? this.accAccessToken
          : this.forgeAccessToken,
        this.client.server_region
      ).then((response) => {
        this.modelTree = response;
      });
    },
    async loadViewer() {
      // TODO fetch data

      // authenticate

      // initialisatie viewer

      let models = this.models.filter((model) => model.enabled);
      if (models.some((model) => model.from_acc) && !this.accAccessToken) {
        this.$cookies.set(ACC_ROUTE, `${this.$route.path}`);
        window.open(
          `https://developer.api.autodesk.com/authentication/v2/authorize?response_type=code&client_id=${
            appConfig.VUE_APP_ACC_CLIENT_ID
          }&redirect_uri=${encodeURI(
            appConfig.VUE_APP_ACC_CLIENT_CALLBACK_URL
          )}&scope=data:read`,
          '_self'
        );
      }
      if (models.length > 0) {
        this.$emit('viewerReloading', models);
        try {
          this.forgeAccessToken = await AutodeskService.fetchToken(
            this.client.client_id,
            this.client.secret,
            this.client.server_region
          );
        } catch (e) {}

        this.getPropertiesOfModels(models);

        // Init viewer
        this.viewerService =
          this.viewerService || new ViewerService(window.Autodesk, this);
        // Remove previous container from the DOM
        const viewerContainer = document.querySelector('.adsk-viewing-viewer');
        if (viewerContainer) {
          viewerContainer.remove();
        }

        this.$once('modelsRendered', async (value) => {
          await this.setExternalMapping(value.myData.urn);
          this.highlightSbsCode();
          this.loadCustomExtensions();
        });
        this.viewerService.LaunchViewer(
          'ant_viewer_forge',
          models.filter((model) => model.from_acc).length > 0
            ? this.accAccessToken
            : this.forgeAccessToken,
          3600,
          this.headless,
          this.client.server_region,
          [...this.extensions],
          this.extensionOptions,
          models.concat()
        );
      }
    },
    async setExternalMapping(urn = null) {
      let viewerModel = this.viewerService.Viewer3D.model;
      if (urn) {
        viewerModel = this.viewerService.Viewer3D.impl
          .modelQueue()
          .getModels()
          .find((x) => urn.includes(x.myData.urn));
      }

      return new Promise((resolve, reject) => {
        viewerModel.getExternalIdMapping(
          (data) => {
            this.externalIdMapping = data;
            this.externalIdMappings[viewerModel.myData.urn] = data;
            return resolve(this.externalIdMappings);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    isolateModelTreeObject(item) {
      this.viewerService.Viewer3D.isolate(item.objectid);
      this.viewerService.Viewer3D.fitToView(item.objectid);
      if (!this.ghostToggle) {
        this.toggleGhosting();
      }
    },
    searchInModel(searchStr) {
      this.viewerService?.search(searchStr);
    },
    clearSearch() {
      this.viewerService?.clearSearch();
      this.$emit('searchCleared');
    },
    /**
     * Fetch model properties if required, stored in this.propertiesOfModels
     * @param models
     */
    getPropertiesOfModels(models) {
      let modelsToBeFetched = [];
      models.forEach((model) => {
        modelsToBeFetched.push(this.fetchModelData(model));
      });
      Promise.all(modelsToBeFetched).then((responses) => {
        this.modelObjects = responses;
        this.$emit('fetchedObjectsByModels', this.modelObjects);
      });
    },
    /**
     * Get Model Data from Autodesk
     * @param model.urn is the base64 URLsafe location of the model
     * @returns Promise
     */
    fetchModelData(model) {
      return new Promise((resolve, reject) => {
        AutodeskService.getModelProperties(
          model.urn,
          model.from_acc ? this.accAccessToken : this.forgeAccessToken,
          this.client.server_region
        ).then((response) => {
          resolve({
            urn: model.urn,
            properties: response,
          });
        });
      });
    },
    fetchFromObject(obj, prop) {
      if (typeof obj === 'undefined') {
        return false;
      }

      const _index = prop.indexOf('.');
      if (_index > -1) {
        return this.fetchFromObject(
          obj[prop.substring(0, _index)],
          prop.substr(_index + 1)
        );
      }

      return obj[prop];
    },
    mapExternalIdsToObjectIds(externalIds) {
      let objectIds = [];
      if (this.externalIdMapping) {
        externalIds.forEach((externalId) => {
          objectIds.push(this.externalIdMapping[externalId]);
        });
      }
      return objectIds;
    },
    mapExternalIdsToObjectIdsMultiModel(urn, externalIds) {
      let objectIds = [];
      if (this.externalIdMappings) {
        externalIds.forEach((externalId) => {
          objectIds.push(this.externalIdMappings[urn][externalId]);
        });
      }
      return objectIds;
    },
    getExternalIdsByNewObjectIds(objectIds) {
      let externalIds = [];
      objectIds.forEach((objectId) => {
        let index = Object.values(this.externalIdMapping).findIndex(
          (value) => value === objectId
        );
        externalIds.push(Object.keys(this.externalIdMapping)[index]);
      });
      return externalIds;
    },
    setCustomMenuItems() {
      const viewer = this.viewerService.Viewer3D;
      viewer.unregisterContextMenuCallback?.('CreateRfiTaskItem');
      viewer.registerContextMenuCallback?.(
        'CreateRfiTaskItem',
        (menu, status) => {
          const eventData = viewer.clientToWorld(
            status.canvasX,
            status.canvasY
          );

          menu.push({
            title: 'Create RFI',
            target: () => {
              if (!eventData) {
                this.showRfiNotificationTooltip = true;
                setTimeout(() => {
                  this.showRfiNotificationTooltip = false;
                }, 4000);
                return;
              }
              const model = eventData.model;
              const modelSbs =
                this.modelsSbsByDbid?.[model.myData.urn]?.[eventData.dbId];

              if (!!modelSbs && !this.checkSbsCodeExists(modelSbs)) {
                this.$store.commit('showNotification', {
                  content: `We have no sbs code (${modelSbs}) in ANT system please create it first`,
                  color: 'error',
                });
                return;
              }
              if (!modelSbs) {
                this.$store.commit('showNotification', {
                  content: `Fragment sbs code wasn't setup`,
                  color: 'error',
                });
                return;
              }

              this.rfiOptions = {
                ...this.rfiOptions,
                forgeRelativeCoords: {
                  x: eventData.point.x,
                  y: eventData.point.y,
                  z: eventData.point.z,
                },
              };
              this.modelSbs = { code: modelSbs };
              this.$store.commit('task_clear');
              this.showRfiDialog = true;
            },
          });
        }
      );
    },
    loadRfisExtension() {
      const viewer = this.viewerService.Viewer3D;
      this.viewerService.Autodesk.Viewing.theExtensionManager.registerExtension(
        ForgeExtension.ANT_RFIS_EXTENSION,
        RfisExtension
      );

      viewer.loadExtension(ForgeExtension.ANT_RFIS_EXTENSION, {
        button: {
          icon: `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
            </svg>`,
          tooltip: 'Show RFI markers',
        },
        data: this.getRfis(),
        onClick: (rfiId) => {
          if (rfiId) {
            this.$store.dispatch('fetchTask', rfiId);
          }
        },
      });
    },
    loadCustomExtensions() {
      this.customExtensions.forEach((extension) => {
        switch (extension) {
          case 'RfisExtension': {
            this.getModelsSbsData().then(() => {
              this.setCustomMenuItems();
              this.loadRfisExtension();
            });
            break;
          }
          default:
            return;
        }
      });
    },
    onRfiCreated() {
      this.$store.dispatch('fetchRfiTasks').then(() => {
        this.loadRfisExtension();
        this.viewerService.Viewer3D.restoreState({
          RfisExtension: { data: this.getRfis() },
        });
      });
    },
    rfiDialogClose() {
      this.showRfiDialog = false;
      this.modelSbs = null;
    },
    getRfis() {
      const filteredRfis = [];
      this.modelsSbsCodes.forEach((sbs) => {
        if (this.checkSbsCodeExists(sbs)) {
          filteredRfis.push(
            ...this.tasksRfi.filter((t) => t?.sbscode?.code === sbs)
          );
        }
      });
      if (filteredRfis.length) {
        return filteredRfis.map((t) => ({
          rfiId: t.id,
          sbsCode: t.sbscode?.code,
          point: t.task_type.rfi_relative_coords
            ? JSON.parse(t.task_type.rfi_relative_coords)
            : null,
          color: ColorHelper.getColorByStatus(t.status),
          number: t.number,
          taskAssigned: t.assigned_to?.id === this.authenticatedUser?.id,
        }));
      }
    },
    async getModelsSbsData() {
      const viewer = this.viewerService.Viewer3D;
      const allModels = viewer.impl.modelQueue().getModels();

      const modelsParams = await allModels.reduce(async (dataAsync, model) => {
        const modelSbsParam = this.models.find(
          (m) => m.urn === model.myData.urn
        )?.sbs_parameter;
        const [category, key] = modelSbsParam?.split('.') || [];
        const instanceTree = model.getData().instanceTree;

        const modelCodes = Object.keys(instanceTree.nodeAccess.dbIdToIndex).map(
          (p) => parseInt(p)
        );

        const props = await this.getProps(
          model,
          modelCodes.map((p) => parseInt(p)),
          { propFilter: [`${key}`] }
        );

        const codesByDbids = props.reduce((acc, p) => {
          const prop = p.properties?.[0];
          const paramsMatch =
            prop?.displayCategory === category &&
            (prop?.displayName === key || prop?.attributeName === key);

          if (paramsMatch) {
            acc[`${p.dbId}`] = prop.displayValue;
          }
          return acc;
        }, {});

        const data = await dataAsync;

        return {
          ...data,
          [`${model.myData.urn}`]: codesByDbids,
        };
      }, Promise.resolve({}));

      this.modelsSbsByDbid = cloneDeep(modelsParams);
      this.modelsSbsCodes = [
        ...new Set(
          Object.values(JSON.parse(JSON.stringify(modelsParams)))
            .reduce((acc, o) => {
              acc = acc.concat(Object.values(o));
              return acc;
            }, [])
            .filter(Boolean)
        ),
      ];
    },
    getProps(model, dbids, options) {
      return new Promise(function (resolve, reject) {
        model.getBulkProperties(dbids, options, resolve, reject);
      });
    },
    checkSbsCodeExists(code) {
      return this.sbsRecords.some((r) => r.code === code);
    },
  },
};
</script>

<style lang="scss" scoped>
.ant-forge-toolbar {
  height: 46px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(8px);
  padding: 2px 10px;
  border-bottom: solid 1px lightgray;

  .ant-toolbar-header {
    font-size: 14px;
    color: #6b6b6b;
  }
}

.forge-models {
  position: absolute;
  left: 20px;
  top: 20px;
  padding: 10px 12px;
  z-index: 2;

  .forge-models-list {
    max-height: 300px;
    overflow-y: scroll;
  }
}

.forge-search {
  position: absolute;
  top: 20px;
  left: 25px;
  z-index: 10;
}

#ant_viewer_forge {
  position: relative;
  height: 100%;

  .ant-forge-overlay-container {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.ant-forge-bottom-panel {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  z-index: 1;
}

.ant-forge-sidebar-right {
}
</style>
